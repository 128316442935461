import { Helmet } from 'react-helmet';
import { useLocation } from 'react-router-dom';
import { useIntl } from 'react-intl';
import * as Sentry from '@sentry/react';

import { Error400 } from '../../../../components/Errors/400/Error400';
import { Error403 } from '../../../../components/Errors/403/Error403';
import { Error500 } from '../../../../components/Errors/500/Error500';
import { Footer } from '../Footer/Footer';

import { ShareError404 } from './Error404';
import './ErrorPage.scss';

function getPageDetails(path: string): { titleKey: `500` | `400` | `403` | `404`; page: React.ReactNode } {
  let pageCode: 500 | 400 | 403 | 404 = 500;
  let page: React.ReactNode = <Error500 />;

  switch (path) {
    case '/400':
      pageCode = 400;
      page = <Error400 />;
      break;
    case '/403':
      pageCode = 403;
      page = <Error403 />;
      break;
    case '/404':
      pageCode = 404;
      page = <ShareError404 />;
      break;
    case '/500':
      pageCode = 500;
      break;
    default:
      pageCode = 500;
      break;
  }
  return { titleKey: `${pageCode}`, page };
}

function ErrorPage() {
  const { formatMessage } = useIntl();
  const path = useLocation().pathname;

  const { titleKey, page } = getPageDetails(path);
  const title = formatMessage({ id: `page.${titleKey}.head.title` });
  const is404 = titleKey === `404`;

  // Log a warning in Sentry
  Sentry.captureMessage(`Error page ${titleKey} - path: ${path}`, 'warning');

  if (is404) {
    return (
      <div>
        <Helmet title={title} />
        {page}
      </div>
    );
  }

  return (
    <div className="ErrorPage">
      <Helmet title={title} />
      <div className="ErrorPage__content">{page}</div>
      <Footer isErrorFooter={true} />
    </div>
  );
}

export function FallbackPage() {
  return (
    <div className="ErrorPage">
      <Helmet title="page 500" />
      <div className="ErrorPage__content">
        <Error500 />
      </div>
      <Footer isErrorFooter={true} />
    </div>
  );
}

// eslint-disable-next-line import/no-default-export -- lazy React component
export default ErrorPage;
