import { PoweredByPledgeBadge, WarningRhombusFilledIcon, Text } from '@pledge-earth/product-language';
import { FormattedMessage } from 'react-intl';

export function ShareError404() {
  return (
    <div className="flex h-screen w-screen flex-col items-center justify-center gap-6 px-2">
      <WarningRhombusFilledIcon className="h-8 w-8 text-default-subdued" />
      <Text className="text-center text-heading-lg">
        <FormattedMessage id="share.error.not_found" />
      </Text>
      <PoweredByPledgeBadge className="fixed bottom-4 right-4" />
    </div>
  );
}
